<template>
  <v-card color="white" dark :to="'/mutation/' + mutation.id">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title
          class="text-h5 black--text font-weight-bold"
          :class="textColor"
          v-text="totalTransaction"
        ></v-card-title>

        <v-card-subtitle class="black--text font-italic text--secondary">
          {{ formatDate(mutation.created_at) }} | {{ mutation.name }}
        </v-card-subtitle>
      </div>

      <v-avatar class="ma-5" tile>
        <v-icon x-large v-if="isPending" color="orange">mdi-cash-sync</v-icon>
        <v-icon x-large v-else-if="isCanceled" color="grey">mdi-cash-remove</v-icon>
        <v-icon x-large v-else-if="isPlus" color="green">mdi-cash-plus</v-icon>
        <v-icon x-large v-else color="red">mdi-cash-minus</v-icon>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
import { formatNumberRupiah, formatDate } from '../helpers/helper'

export default {
  props: {
    mutation: Object
  },
  computed: {
    totalTransaction() {
      return (this.isCanceled || this.isPending ? '' : this.isPlus ? '+ ' : '- ') + formatNumberRupiah(this.mutation.total)
    },
    textColor() {
      return this.isPending
        ? 'orange--text'
        : this.isCanceled
        ? 'grey--text'
        : this.isPlus
        ? 'green--text'
        : 'red--text'
    },
    isPlus() {
      return ['refund', 'topup', 'plus'].includes(this.mutation.type)
    },
    isCanceled() {
      return this.mutation.status == 'cancel'
    },
    isPending() {
      return this.mutation.status == 'pending'
    }
  },
  methods: {
    formatDate
  }
}
</script>