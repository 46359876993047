<template>
  <v-card class="mx-auto rounded-xl mb-4" color="blue darken-2">
    <v-card-text class="pa-5">
      <div class="d-flex justify-space-between flex-no-wrap align-center">
        <div>
          <div class="white--text">Saldo</div>
          <p class="text-h4 white--text mb-1">{{ balanceFormat }}</p>
        </div>

        <v-avatar class="ma-5" tile>
          <v-icon x-large color="white">mdi-wallet</v-icon>
        </v-avatar>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { formatNumberRupiah } from '../helpers/helper'
export default {
  computed: {
    ...mapState({
      balance: (state) => state.balance.balance
    }),
    balanceFormat() {
      return formatNumberRupiah(this.balance !== undefined ? this.balance.balance : 0)
    }
  }
}
</script>