<template>
  <v-container>
    <v-row dense>
      <v-col cols="12"> <CardBalance v-if="balance" /> </v-col>
      <v-col v-for="(item, index) in mutations" :key="index" cols="12">
        <card-mutation :mutation="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardMutation from '../components/CardMutation.vue'
import CardBalance from '../components/CardBalance.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Balance',
  components: {
    CardMutation,
    CardBalance
  },
  computed: {
    ...mapState({
      balance: (state) => state.balance.balance,
      mutations: (state) => state.mutation.mutations
    })
  },
  methods: {
    ...mapActions('mutation', ['getAllMyMutation']),
    ...mapActions('balance', ['getBalance']),
    getMutations() {
      this.getBalance()
      this.getAllMyMutation()
    }
  },
  mounted() {
    this.getMutations()
  }
}
</script>